<template>
  <div class="container">
    <div class="grid">
      <div class="cell cell-1">
        <div :class="'img' + (active % 10)">
          <div class="description">
            {{ descriptions[active % 10] }}
          </div>
        </div>
      </div>
      <div class="cell cell-2">
        <div :class="'img' + ((active + 1) % 10)">
          <div class="description">
            {{ descriptions[(active + 1) % 10] }}
          </div>
        </div>
      </div>
      <div class="cell cell-3">
        <div :class="'img' + ((active + 2) % 10)">
          <div class="description">
            {{ descriptions[(active + 2) % 10] }}
          </div>
        </div>
      </div>
    </div>
    <div :class="'img' + ((active + 1) % 10)" class="preload"></div>
    <div :class="'img' + ((active + 2) % 10)" class="preload"></div>
    <div :class="'img' + ((active + 3) % 10)" class="preload"></div>
  </div>
</template>

<script>
export default {
  name: "Illustrations",
  data() {
    return {
      active: 0,
      descriptions: [
        "Alimentation équilibrée",
        "Burnout",
        "Compulsion alimentaire",
        "Douleur articulaire",
        "Manque d'énergie",
        "Grossesse et pré conception",
        "Stress",
        "Troubles digestifs",
        "Trouble de l'immunité",
        "Troubles métaboliques",
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.active++;
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
$transition-time: 0.5s;
.image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 300px;
  -webkit-transition: all $transition-time ease;
  -moz-transition: all $transition-time ease;
  -o-transition: all $transition-time ease;
  transition: all $transition-time ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  @media screen and (max-width: $responsive-l) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $responsive-m) {
    grid-template-columns: 1fr;
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cell-2 {
    @media screen and (max-width: $responsive-m) {
      display: none;
    }
  }
  .cell-3 {
    @media screen and (max-width: $responsive-l) {
      display: none;
    }
  }
}
.img0 {
  @extend .image;
  background-image: url("../assets/images/illustrations/alimentation-equilibrée.jpg");
}
.img1 {
  @extend .image;
  background-image: url("../assets/images/illustrations/burnout.jpg");
}
.img2 {
  @extend .image;
  background-image: url("../assets/images/illustrations/compulsion-alimentaire.jpg");
}
.img3 {
  @extend .image;
  background-image: url("../assets/images/illustrations/douleur-articulaire.jpg");
}
.img4 {
  @extend .image;
  background-image: url("../assets/images/illustrations/manque-energie.jpg");
}
.img5 {
  @extend .image;
  background-image: url("../assets/images/illustrations/pregnant.jpg");
}
.img6 {
  @extend .image;
  background-image: url("../assets/images/illustrations/stress.jpg");
}
.img7 {
  @extend .image;
  background-image: url("../assets/images/illustrations/trouble-digestif.jpg");
}
.img8 {
  @extend .image;
  background-image: url("../assets/images/illustrations/trouble-immunite.jpg");
}
.img9 {
  @extend .image;
  background-image: url("../assets/images/illustrations/trouble-metabolique.jpg");
}
.description {
  text-align: center;
  background-color: $primary;
  padding: 5px 0;
  color: white;
  font-weight: bold;
}
.preload {
  height: 0;
}
</style>
