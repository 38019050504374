import { render, staticRenderFns } from "./Carrousel.vue?vue&type=template&id=097d0791&scoped=true&"
import script from "./Carrousel.vue?vue&type=script&lang=js&"
export * from "./Carrousel.vue?vue&type=script&lang=js&"
import style0 from "./Carrousel.vue?vue&type=style&index=0&id=097d0791&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097d0791",
  null
  
)

export default component.exports