<template>
  <div class="container">
    <h1>Qui suis-je ?</h1>
    <p>
      Passionnée de sport et de micronutrition
    </p>
    <p>
      Initialement diplômée comme enseignante, passionnée de sport, je me suis
      depuis toujours intéressée à la nutrition afin dans un premier temps
      d’améliorer mes performances. Petit à petit, je prends conscience des
      réels pouvoirs de la nutrition et de son influence certaine sur le bien
      être et la santé. Par mes rencontres, mes lectures et recherches j’en fait
      ma propre démonstration en réglant par le seul biais de la nutrition
      quelques soucis de santé. Ma passion et motivation sont nées et convaincue
      des liens évidents entre la nutrition, le bien-être et la santé, j’ai
      voulu comprendre les mécanismes d’action des nutriments et micronutriments
      sur le corps humain.
    </p>
    <p>
      J’ai donc suivi après une formation d’un an en naturopathie, 3 années
      d'études en nutrition au CERDEN, Centre Européen pour la Recherche, le
      Développement et l’Enseignement de la Nutrition et de la Nutrithérapie.
      Cela ne suffit pas à assouvir ma passion. Je reprends ensuite une année de
      perfectionnement au Cerden.
    </p>
    <p>
      Cette passion, je la cultive et la fait grandir par de multiples lectures
      et conférences.
    </p>
    <p>
      Je suis aussi membre du commité de réflexion de l’ASBL regroupant tous les
      conseillers en nutrition de Belgique.
    </p>
    <p>
      Maman de trois enfants et très active, je suis constamment à la recherche
      de recettes santé à la fois délicieuses, saines, rapides et faciles à
      réaliser.
    </p>
    <p>
      Ce sont toutes ces connaissances en nutrition, recherches et expériences
      que j’ai à cœur de partager avec vous, en les adaptant à vos propres modes
      de vie, besoins et envies. Ma motivation première est de vous aider.
    </p>
    <p>
      Je suis impatiente de partager avec vous la manière de faire rimer
      «plaisir de manger » avec « bien-être, énergie et santé » ...
    </p>
  </div>
</template>

<script>
export default {
  name: "Me",
};
</script>

<style lang="scss" scoped></style>
