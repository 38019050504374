<template>
  <div id="home">
    <div class="container">
      <h1>Je vous propose</h1>
      <div class="card">
        <div class="card-title" data-aos="fade-right">
          <h2>Consultations :</h2>
        </div>
        <div class="card-content">
          <ul>
            <li>
              <b
                >une analyse précise de votre métabolisme par une
                impédancemètrie</b
              >
              : masse graisseuse, protéique, musculaire, minérale, hydrique,
              etc.
            </li>
            <li>
              une <b>approche globale</b> et <b>très personnalisée</b> car vous
              êtes unique (analyse de vos habitudes alimentaires, mode de vie,
              stress, perturbations…).
            </li>
            <li>
              une prise en charge fine au
              <b>niveau micronutritionnel</b> (rééquilibrage de l’assiette et/ou
              compléments alimentaires)
            </li>
            <li>
              une importance centrale accordée au <b>plaisir de manger</b>
            </li>
            <li>
              un accent sur la <b>qualité</b> et la
              <b>densité nutritionnelle</b> des aliments
            </li>
            <li>
              pas de régime mais des <b>clés de compréhension</b> et une
              rééducation alimentaire avec une visée à <b>long terme</b>
            </li>
            <li>
              <b>des outils pratiques</b> pour vous amener vers
              <b>l’autonomie</b>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-title" data-aos="fade-right">
          <h2>Terrains d’action :</h2>
        </div>
        <div class="card-content">
          <ul>
            <li>Rééquilibrage, bien-être, prévention santé</li>
            <li>Santé de la femme</li>
            <li>Troubles métaboliques</li>
            <li>Troubles de l‘énergie et de l’humeur</li>
            <li>Sport et alimentation</li>
            <li>Burnout</li>
            <li>Grossesse et préconception</li>
            <li>Stress</li>
            <li>Manque d'énergie, fatigue</li>
            <li>Troubles digestifs, intolérances alimentaires, microbiote</li>
            <li>Compulsions alimentaires</li>
            <li>Troubles de l'immunité</li>
            <li>Douleurs musculaires et articulaires</li>
            <li>Alimentation équilibrée</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped></style>
