<template>
  <div id="app">
    <Header />
    <div id="accueil"></div>
    <div id="accueil-section">
      <Carrousel />
      <Home />
      <Illustrations />
    </div>

    <div id="me"></div>
    <div id="me-section">
      <div class="section-other-color">
        <Me />
      </div>
    </div>

    <div id="nutri"></div>
    <div id="nutri-section">
      <Nutri />
    </div>

    <div id="consultations"></div>
    <div id="consultations-section">
      <div class="section-other-color">
        <Consultations />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Carrousel from "./components/Carrousel";
import Home from "./components/Home";
import Me from "./components/Me";
import Nutri from "./components/Nutri";
import Consultations from "./components/Consultations";
import Illustrations from "./components/Illustrations";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Carrousel,
    Home,
    Me,
    Nutri,
    Consultations,
    Illustrations,
    Footer,
  },
  mounted() {
    if (window.location.href.includes("#")) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, " ");
    }
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#accueil,
#me,
#nutri,
#consultations {
  position: relative;
  top: -57px;
}
</style>
