<template>
  <div class="container">
    <h1>Les consultations</h1>
    <p>
      Les consultations sont sur rendez-vous du lundi au vendredi (sauf le
      mardi) de 14h à 19h.
    </p>
    <h2>Où ?</h2>
    <ol>
      <li>
        le premier et troisième jeudi après-midi du mois au cabinet médical de Rotheux rue Maflot 8
        <div class="map">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2536.002930836183!2d5.477892276562184!3d50.534116371608235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0573333d31dcf%3A0xa1f90be2952822a2!2sCentre%20m%C3%A9dical%20Rotheux!5e0!3m2!1sfr!2sbe!4v1693122345390!5m2!1sfr!2sbe" 
            width="100%"
            height="450"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
           ></iframe>
        </div>
      </li>
      <li>
        les mercredis, jeudis et vendredis rue Aux Passages 18, 4122 Plainevaux
        <div class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2535.2998331499352!2d5.525054515569598!3d50.54719388827056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c057d8181e5d9f%3A0xf9585ab4b92795da!2sRue%20aux%20Passages%2018%2C%204122%20Neupr%C3%A9!5e0!3m2!1sfr!2sbe!4v1590836334081!5m2!1sfr!2sbe"
            width="100%"
            height="450"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </li>
    </ol>
    <p>
      Prise de rdv en ligne :
      <a href="https://nutrinatsante.reservio.com" target="_blank"
        >https://nutrinatsante.reservio.com</a
      >
    </p>
    <h2>
      Consultations : comment cela se passe-t-il ?
    </h2>
    <div class="card">
      <div class="card-title" data-aos="fade-right">
        <h3>
          Vous écouter
        </h3>
      </div>
      <div class="card-content">
        <ul>
          <li>
            Bien <b>comprendre</b> votre situation. <b>Vous êtes unique</b> !
          </li>
          <li>
            <b>Analyser avec vous</b> vos habitudes alimentaires et votre mode
            de vie
          </li>
          <li>
            Identifier vos <b>forces</b> et vos <b>potentiels d’amélioration</b>
          </li>
          <li>Réaliser une <b>impédancemétrie</b> détaillée</li>
          <li>
            Mettre en lien certaines <b>perturbations</b> avec d’éventuelles
            <b>carences micronutritionnelles</b>
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-title" data-aos="fade-right">
        <h3>Vous guider</h3>
      </div>
      <div class="card-content">
        <ul>
          <li>Vous proposer des <b>clés pour comprendre</b> votre situation</li>
          <li>
            Vous expliquer comment <b>agir sur votre assiette</b> pour vous
            sentir mieux
          </li>
          <li>
            Vous suggérer, si nécessaire, les
            <b>compléments alimentaires</b> les plus <b>appropriés</b> et les
            plus <b>biodisponibles</b>
          </li>
          <li>
            Fixer ensemble des <b>objectifs réalistes</b>, des <b>priorités</b>,
            un
            <b>plan d’action concret</b>
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-title" data-aos="fade-right">
        <h3>
          Vous motiver
        </h3>
      </div>
      <div class="card-content">
        <ul>
          <li>
            Vous montrer que <b>nutrition santé et plaisir de la table</b> sont
            tout à fait compatibles !
          </li>
          <li>
            Vous donner rapidement un <b>regain d’énergie</b> grâce à quelques
            <b>aménagements immédiats et faciles à mettre en oeuvre</b>
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-title" data-aos="fade-right">
        <h3>
          Vous mener vers l’autonomie ...
        </h3>
      </div>
      <div class="card-content">
        <ul>
          <li>
            Vous donner le maximum de <b>clés de compréhension</b> de votre vécu
          </li>
          <li>
            Mettre à votre disposition des <b>outils très concrets</b> pour
            rendre la <b>mise en oeuvre facile</b> de votre plan d’action à la
            maison
          </li>
          <li>
            <b>Répondre</b> le plus clairement possible <b>à vos questions</b> à
            chaque entrevue
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-title" data-aos="fade-right">
        <h3>
          Faire en sorte que votre cheminement soit un vrai bonheur !
        </h3>
      </div>
      <div class="card-content">
        <ul>
          <li>
            Vous faire découvrir de <b>nouveaux produits</b>, de
            <b>nouvelles saveurs</b>, de <b>nouvelles recettes</b>, de nouveaux
            magasins, de <b>nouveaux horizons</b> ...
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Consultations",
};
</script>

<style lang="scss" scoped>
.map {
  padding: 10px 0;
}
</style>
