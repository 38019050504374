<template>
  <footer class="container">
    <div class="content">
      <img src="../assets/images/profile.jpg" alt="photo de profil" />
    </div>
    <div class="content">
      Deprez Nathalie Naturopathe et Conseillère en Nutrition Humaine certifiée
      CERDEN (Centre Européen de Recherche, de Développement, de l'Enseignement
      et de la Nutrition). Monitrice JCPMF
    </div>
    <div class="content">
      <div>"Que ton aliment soit ton médicament" <br />- Hippocrate</div>
      <hr />
      <div>
        Soyez acteur de votre santé et de votre bien-être grâce à votre
        alimentation.
      </div>
    </div>
    <div class="coords content">
      <div class="coord">
        <div class="logo"><i class="gg-mail"></i></div>
        <div class="text-align-left">
          <a href="mailto:nutrinatsante@hotmail.com"
            >nutrinatsante@hotmail.com</a
          >
        </div>
      </div>
      <div class="coord">
        <div class="logo"><i class="gg-phone"></i></div>
        <div class="text-align-left">
          <a href="tel:+32497872846">+32497872846</a>
        </div>
      </div>
      <div class="text-align-left">
        (joignable de 14h à 19h sauf le mardi uniquement par SMS)
      </div>
    </div>
    <div class="content fb">
      <iframe
        src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FNutrinatsant%25C3%25A9-116507453522958&width=156&layout=button_count&action=like&size=small&share=true&height=46&appId=793011078127604"
        width="156"
        height="46"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import url("https://css.gg/mail.css");
@import url("https://css.gg/phone.css");
footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  .content {
    width: 300px;
    margin: 15px;
  }
}
img {
  width: 200px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.coords {
  .coord {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 10px 0;
    .logo {
      width: 20px !important;
    }
    .gg-mail {
      position: relative;
      top: 5px;
    }
    .gg-phone {
      position: relative;
      top: 5px;
    }
  }
}
.fb {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-align-left {
  text-align: left;
}
</style>
