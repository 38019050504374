<template>
  <div id="header">
    <header>
      <div class="desktop">
        <div class="item">
          <a
            href="https://www.facebook.com/Nutrinatsant%C3%A9-116507453522958"
            class="fb"
            target="_blank"
            ><i class="gg-facebook"></i
          ></a>
        </div>
        <div
          class="item"
          :class="{ active: active === link.id }"
          v-for="link in links"
          :key="link.href"
        >
          <a :href="link.href" :target="link.target" class="link">{{
            link.text
          }}</a>
        </div>
        <div class="item">
          <a
            href="https://nutrinatsante.reservio.com/"
            target="_blank"
            class="link"
            >Prendre RDV</a
          >
        </div>
      </div>
      <div class="mobile">
        <div class="item">
          <a
            href="https://www.facebook.com/Nutrinatsant%C3%A9-116507453522958"
            class="fb"
            target="_blank"
            ><i class="gg-facebook"></i
          ></a>
        </div>
        <div class="item">
          <a
            href="https://nutrinatsante.reservio.com/"
            target="_blank"
            class="link"
            >Prendre RDV</a
          >
        </div>
        <div class="burger-logo" v-on:click="toggleMenu">
          <i :class="isBurgerOpened ? 'gg-close' : 'gg-menu-boxed'"></i>
        </div>
        <div class="nav" :class="{ closed: !isBurgerOpened }">
          <div class="item" v-for="link in links" :key="link.href">
            <a
              :href="link.href"
              :target="link.target"
              class="link"
              :class="{ active: active === link.id }"
              v-on:click="isBurgerOpened = false"
              >{{ link.text }}</a
            >
          </div>
          <div class="item">
            <a
              href="https://nutrinatsante.reservio.com/"
              target="_blank"
              class="link"
              >Prendre RDV</a
            >
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      active: null,
      isBurgerOpened: false,
      links: [
        {
          id: "accueil",
          href: "#accueil",
          text: "Accueil",
        },
        {
          id: "me",
          href: "#me",
          text: "Qui suis-je",
        },
        {
          id: "nutri",
          href: "#nutri",
          text: "La nutrithérapie",
        },
        {
          id: "consultations",
          href: "#consultations",
          text: "Consultations",
        },
      ],
    };
  },
  methods: {
    toggleMenu(event) {
      event.preventDefault();
      this.isBurgerOpened = !this.isBurgerOpened;
      return false;
    },
    checkActive() {
      this.links
        .map((l) => l.id)
        .filter((l) => !!l)
        .forEach((id) => {
          if (this.isActive(document.getElementById(id + "-section"))) {
            this.active = id;
          }
        });
    },
    /**
     * @param {HTMLElement} el
     */
    isActive(el) {
      // if more than 50% in the screen ==> active
      return el.getBoundingClientRect().top < window.innerHeight / 2;
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.checkActive();
    });
    this.checkActive();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://css.gg/menu-boxed.css");
@import url("https://css.gg/close.css");
@import url("https://css.gg/facebook.css");
@import "../styles/variables.scss";
#header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}
@media (max-width: $responsive-m) {
  .desktop {
    display: none !important;
  }
}
@media (min-width: $responsive-m) {
  .mobile {
    display: none !important;
  }
}
.hidden {
  display: none;
}
.mobile {
  background: $primary;
  display: flex;
  justify-content: space-between;
  .burger-logo {
    display: inline-block;
    cursor: pointer;
    z-index: 1;
    padding: 20px;
    color: white;
  }
  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    background: $primary;
    display: inline-block;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &.closed {
      border-radius: 100px;
      transform: translate(0, -100%);
    }
  }
}
.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  padding: 10px;
}
.item {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .link {
    text-decoration: none;
    color: white;
    font-size: 20px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    &:hover {
      color: rgb(218, 218, 218);
    }
  }
}
.active {
  border-bottom: 2px solid white;
}
.fb {
  color: white;
  width: 50px;
}
</style>
