<template>
  <div id="carrousel">
    <div :class="'image' + (current + 1)">
      <div class="description">{{ descriptions[current] }}</div>
    </div>
    <div :class="'preload image' + (post + 1)"></div>
  </div>
</template>

<script>
export default {
  name: "Carrousel",
  data() {
    return {
      current: 0,
      post: 1,
      descriptions: [
        "Nutrition et bien être",
        "Le plaisir de manger",
        "L’art d’apprendre à manger sain et malin",
        "Ma santé dans mon assiette",
      ],
    };
  },
  mounted() {
    setInterval(() => {
      if (this.current === 3) {
        this.current = 0;
        this.post = null;
        return;
      }
      this.current++;
      this.post++;
    }, 7000);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
$transition-time: 0.5s;
.description {
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: white;
  padding: 20px;
  font-weight: bold;
}
.image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  -webkit-transition: all $transition-time ease;
  -moz-transition: all $transition-time ease;
  -o-transition: all $transition-time ease;
  transition: all $transition-time ease;
}
.image1 {
  @extend .image;
  background-image: url("../assets/images/m/appetizer-avocado-bread-breakfast-566566.jpg");
  @media screen and (min-width: $responsive-m) {
    background-image: url("../assets/images/l/appetizer-avocado-bread-breakfast-566566.jpg");
  }
  @media screen and (min-width: $responsive-l) {
    background-image: url("../assets/images/xl/appetizer-avocado-bread-breakfast-566566.jpg");
  }
}
.image2 {
  @extend .image;
  background-image: url("../assets/images/m/asparagus-barbecue-cuisine-delicious-361184.jpg");
  @media screen and (min-width: $responsive-m) {
    background-image: url("../assets/images/l/asparagus-barbecue-cuisine-delicious-361184.jpg");
  }
  @media screen and (min-width: $responsive-l) {
    background-image: url("../assets/images/xl/asparagus-barbecue-cuisine-delicious-361184.jpg");
  }
}
.image3 {
  @extend .image;
  background-image: url("../assets/images/m/cooed-food-2103949.jpg");
  @media screen and (min-width: $responsive-m) {
    background-image: url("../assets/images/l/cooed-food-2103949.jpg");
  }
  @media screen and (min-width: $responsive-l) {
    background-image: url("../assets/images/xl/cooed-food-2103949.jpg");
  }
}
.image4 {
  @extend .image;
  background-image: url("../assets/images/m/flat-lay-photography-of-vegetable-salad-on-plate-1640777.jpg");
  @media screen and (min-width: $responsive-m) {
    background-image: url("../assets/images/l/flat-lay-photography-of-vegetable-salad-on-plate-1640777.jpg");
  }
  @media screen and (min-width: $responsive-l) {
    background-image: url("../assets/images/xl/flat-lay-photography-of-vegetable-salad-on-plate-1640777.jpg");
  }
}
.preload {
  height: 0;
}
</style>
