<template>
  <div class="container">
    <h1>La nutrithérapie</h1>
    <p>
      <b>La nutrithérapie</b>,médecine par la micro-nutrition cellulaire, est
      née aux Etats-Unis il y a plus de 35 ans. Elle représente une véritable
      révolution dans l’approche de la maladie et dans la conception même de la
      santé. Elle vise à optimiser la santé, les performances physiques et
      intellectuelles, retarder les effets du vieillissement, prévenir les
      risques de maladie et les traiter par des conseils alimentaires et une
      supplémentation active en micronutriments.
    </p>
    <p>
      80 % de nos maux sont induits par notre mode de vie moderne et notre
      alimentation déséquilibrée. Le constat de l’apparition progressive d’une
      alimentation déficiente - et donc à risque - dans nos sociétés modernes
      donne d’autant plus d’importance à la nécessité de corriger, en
      connaissance de cause, les carences et les excès qui en découlent sur le
      plan micro-nutritionnel et ce en utilisant les corrections alimentaires et
      aussi l’apport des différents micronutriments nécessaires (en
      supplémentation ou complémentation) pour retrouver la santé ou pallier aux
      inconvénients d’une pathologie installée.
    </p>
    <p>
      L’efficacité de la nutrithérapie n’est, aujourd’hui, plus contestable. Des
      milliers d’études scientifiques sont venues confirmer les résultats
      obtenus chaque jour en cabinet.
    </p>
    <p>
      <b>L’action de la nutrithérapie se situe essentiellement à 3 niveaux:</b>
    </p>

    <ol>
      <li>
        correction alimentaire pour chacun afin de permettre de conserver une
        bonne santé.
      </li>
      <li>
        adaptation de l’alimentation et supplémentation pour certaines
        catégories de consultants (sportifs, adolescents, femmes enceintes,
        personnes âgées, ...)
      </li>
      <li>
        adaptation alimentaire et supplémentation pour de nombreux cas
        pathologiques (troubles cardio-vasculaires ou digestifs, problèmes
        gynécologiques, cancers, affections rhumatismales, stress et
        dépression...)
      </li>
    </ol>
    <p>
      Le but de la Nutrithérapie est donc d’augmenter l’espérance de vie en
      maintenant une santé optimale, en privilégiant et en stimulant ou en
      restaurant les fonctions physiologiques.
    </p>
    <p>
      A ce titre, elle fait partie de la médecine fonctionnelle et devient un
      des atouts principaux de la médecine du 21ème siècle.
    </p>
  </div>
</template>

<script>
export default {
  name: "Nutri",
};
</script>

<style lang="scss" scoped></style>
